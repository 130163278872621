<template>
  <div>
    <div class="page-header">
      <h1>
        {{ $tc("user", 2) }}
      </h1>
      <b-button
        type="is-primary"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'UserCreate' }"
      >
        {{ $t("user_create") }}
      </b-button>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <TableFilter :filter="filter"></TableFilter>

        <b-table
          ref="table"
          :current-page="page"
          :data="users"
          :loading="loading"
          :total="total"
          backend-pagination
          class="is-clickable mt-2"
          hoverable
          paginated
          pagination-simple
          pagination-size="is-small"
          per-page="30"
          @click="
            (e) =>
              this.$router.push({
                name: 'UserView',
                params: { id: e.id },
              })
          "
          @page-change="(e) => (page = e)"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column :label="$t('name')">
            <template v-slot="props">
              {{ props.row.name }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('email')">
            <template v-slot="props">
              {{ props.row.emailAddress }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('phone')">
            <template v-slot="props">
              {{ props.row.phoneNumber }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('language', 1)">
            <template v-slot="props">
              {{ props.row.language.isoCode | uc }}
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TableFilter from "../../components/TableFilter";

export default {
  name: "UserIndex",
  components: { TableFilter },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      users: [],
      filter: {
        search: null,
      },
    };
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getUsers"]),
    load() {
      this.loading = true;

      this.getUsers({
        page: this.page,
        name: this.filter.search,
        emailAddress: this.filter.search,
      }).then(({ items, total }) => {
        this.users = items;
        this.total = total;

        this.loading = false;
      });
    },
  },
};
</script>
